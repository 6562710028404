import React, {useState, useRef, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import useWebcam from '../../../hooks/useWebcam';
import {Heading, Title} from '../../../atoms/Typography';
import Button from '../../../atoms/Button';
import eyes from '../../../assets/eye.png';
import arrow from '../../../assets/icons/round-arrow.svg';
import {PHASES} from './';
import {Wrapper} from '../../../atoms/Layout';
import CountDown from '../../../atoms/CountDown';
import ImageModal from '../../components/Modal/ImageModal';
import positioningHelper from '../../../assets/positioning-helper.png';

interface Props {
  phase: PHASES;
  onNext: (image?: string) => void;
  onCancel: () => void;
  takePhoto?: boolean;
  endTime?: number;
  timerReady?: boolean;
}

const Step: React.FC<Props> = ({phase, onNext, onCancel, takePhoto, timerReady}) => {
  const {t} = useTranslation();
  const webcam = useWebcam(400, 200);
  const [phaseEnd, setPhaseEnd] = useState(Date.now() + 5000);
  const [showHelper, setShowHelper] = useState(false);
  const [okButtonDisabled, setOkButtonDisabled] = useState(true);
  const history = useHistory();

  const videoRef = useRef<HTMLVideoElement>(null);

  const phaseTitleMap = {
    [PHASES.START]: '',
    [PHASES.SETUP]: 'redness.setup',
    [PHASES.LEFT]: 'redness.left',
    [PHASES.RIGHT]: 'redness.right',
    [PHASES.UP]: 'redness.up',
    [PHASES.REVIEW]: '',
  };

  const SETUP_AUDIO_DURATION = 7000;

  const handleClick = useCallback(() => onNext(), [onNext]);

  const handlePicture = useCallback(async () => {
    if (!videoRef.current) {
      return;
    }

    const photo = await webcam.takePicture(videoRef.current);

    onNext && onNext(photo);
    if (phase === PHASES.UP) {
      webcam.stopStream();
    }
  }, [webcam.takePicture, onNext, videoRef, phase]);

  const handleCloseModal = useCallback(() => {
    setShowHelper(false);
    okButtonDisabled && setOkButtonDisabled(false);
  }, [okButtonDisabled]);

  useEffect(() => {
    webcam.startStream().catch(() => {
      history.push('/permissions');
    });

    return () => webcam.stopStream();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = webcam.stream;
    }
  }, [webcam.stream]);

  useEffect(() => {
    if (takePhoto && timerReady) {
      setPhaseEnd(Date.now() + 3000);

      setTimeout(() => {
        (async () => {
          await handlePicture();
        })();
      }, 3000);
    }
  }, [takePhoto, timerReady]);

  /** Show positioning helper after the audio instruction has ended **/
  useEffect(() => {
    setTimeout(() => setShowHelper(true), SETUP_AUDIO_DURATION);
  }, []);

  const isSetupPhase = phase === PHASES.SETUP;

  return (
    <StyledWrapper lightGray>
      <Container>
        <HeaderContainer>
          <div>
            <CancelButton onClick={onCancel} grey>
              {t('action.cancel')}
            </CancelButton>
          </div>

          {isSetupPhase ? (
            <Heading>{t(phaseTitleMap[phase])}</Heading>
          ) : (
            <Title>{t(phaseTitleMap[phase])}</Title>
          )}
        </HeaderContainer>
        {!takePhoto && (
          <ReadyButton onClick={handleClick} disabled={okButtonDisabled}>
            {t('redness.ready')}
          </ReadyButton>
        )}
        <VideoContainer flash={false}>
          <Video ref={videoRef} playsInline autoPlay />

          <EyePlacement />
          {!isSetupPhase && <Arrow phase={phase} />}
        </VideoContainer>
        {!takePhoto && (
          <HelpButtonContainer>
            <StyledButton inverted onClick={() => setShowHelper(true)}>
              {t('redness.positioning.buttonText')}
            </StyledButton>
          </HelpButtonContainer>
        )}
        {takePhoto && (
          <FooterContainer>
            <CountDown
              endTime={phaseEnd}
              darkText
              key={phase}
              disabled={!timerReady}
              initialTime={3}
            />
          </FooterContainer>
        )}
      </Container>
      <ImageModal
        title={t('redness.positioning.title')}
        buttonText={t('redness.positioning.actionText')}
        imageUrl={positioningHelper}
        visible={showHelper}
        onClose={handleCloseModal}
        onAction={handleCloseModal}
      />
    </StyledWrapper>
  );
};

export default Step;

const StyledWrapper = styled(Wrapper)`
  padding-bottom: 32px;
`;

const Container = styled.div`
  min-height: 100%;
  max-width: 612px;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;

  @media ${props => props.theme.breakpoints.mobile} {
    justify-content: space-between;
    padding-bottom: 32px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  margin: 48px 0;
`;

const FooterContainer = styled.div`
  margin-top: -32px;
  max-height: 75px;
`;

interface Timer {
  flash: boolean;
}

const VideoContainer = styled.div<Timer>`
  position: relative;
  width: 612px;
  height: 331px;
  opacity: ${({flash}) => (flash ? 0.3 : 1)};
  transition: 100ms;
  margin-bottom: 80px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 312px;
    height: 169px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  background-color: black;
  transform: scaleX(-1);
`;

const HelpButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const EyePlacement = styled.img.attrs({src: eyes})`
  position: absolute;
  top: 122px;
  left: 110px;
  width: 392px;
  height: 88px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 200px;
    height: 45px;
    top: calc(50% - 22px);
    left: calc(50% - 100px);
  }
`;

interface PHASE {
  phase: PHASES;
}

const Arrow = styled.img.attrs({src: arrow})<PHASE>`
  position: absolute;
  height: 62px;
  width: 62px;
  left: calc(50% - 31px);
  top: -31px;

  transform: ${({phase}) => {
    if (phase === PHASES.RIGHT) {
      return 'rotate(180deg)';
    }

    if (phase === PHASES.UP) {
      return 'rotate(90deg)';
    }

    return 'rotate(0deg)';
  }};
`;

const CancelButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 32px;
  }
`;

const ReadyButton = styled(Button)`
  margin-bottom: -22px;
  z-index: 2;
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 16px;
  }

  &.Mui-disabled {
    background-color: ${props => props.theme.colors.grey200};
  }
`;

const StyledButton = styled(Button)`
  z-index: 2;
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 16px;
  }

  &.Mui-disabled {
    background-color: ${props => props.theme.colors.grey200};
  }
`;
