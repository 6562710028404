import React, {useCallback, useContext} from 'react';
import merge from 'deepmerge';
import styled, {ThemeProvider, ThemeContext} from 'styled-components';

import {ThemeProvider as MaterialThemeProvider} from '@material-ui/core';

import Footer from '../Footer';

interface BackgroundLight {
  lightGray?: boolean;
  dark?: boolean;
  light?: 'left' | 'bottom';
}

interface WrapperProps {
  header?: React.ReactNode;
  footer?: boolean | React.ReactNode;
  className?: string;
}

type Props = WrapperProps & BackgroundLight;

export const Wrapper: React.FC<Props> = ({
  dark,
  header,
  footer,
  light,
  lightGray,
  children,
  className,
}) => {
  const styledTheme = useContext(ThemeContext);

  let footerComp = null;
  if (footer === true) {
    footerComp = <Footer />;
  } else if (footer) {
    footerComp = <Footer>{footer}</Footer>;
  }

  let headerComp = null;
  if (header) {
    headerComp = <Header>{header}</Header>;
  }

  const darkTheme = useCallback(
    (theme: any) => {
      if (!dark) {
        return theme;
      }

      const newTheme: any = merge({}, theme);
      newTheme.colors.black = styledTheme.colors.white;
      newTheme.colors.gray = styledTheme.colors.white;
      newTheme.colors.lightGray = styledTheme.colors.white;
      return newTheme;
    },
    [dark, styledTheme]
  );

  const materialDarkTheme = useCallback(
    (theme: any) => {
      if (!dark) {
        return theme;
      }

      const newTheme: any = merge({}, theme);
      newTheme.darkMode = true;
      newTheme.palette.primary.main = styledTheme.colors.white;
      newTheme.palette.primary.contrastText = styledTheme.colors.main;

      return newTheme;
    },
    [dark, styledTheme]
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <MaterialThemeProvider theme={materialDarkTheme}>
        <Container dark={dark} light={light} lightGray={lightGray}>
          <Inside className={className}>
            {headerComp}
            <Content header={!!headerComp} footer={!!footerComp}>
              {children}
            </Content>
            {footerComp}
          </Inside>
        </Container>
      </MaterialThemeProvider>
    </ThemeProvider>
  );
};

const Container = styled.div<BackgroundLight>`
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  @media ${props => props.theme.breakpoints.mobile} {
    height: auto;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  
  ${props => props.lightGray && `background-color: ${props.theme.colors.wrapperBackgroundLeft};`}  
  ${props => props.dark && `background-color: ${props.theme.colors.darkBackground};`}  
  
  ${props =>
    props.light === 'left' &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 47%;
      display: block;
      z-index: 0;
      box-shadow: ${props.theme.shadows.wrapper};
      background-color: ${props.theme.colors.wrapperBackgroundLeft};
    }
  `}
  
  
  @media ${props => props.theme.breakpoints.mobile} {
    ${props =>
      props.light === 'left' &&
      `
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 275px;
        width: 100%;
        display: block;
        z-index: 0;
        box-shadow: ${props.theme.shadows.wrapper};
        background-color: ${props.theme.colors.wrapperBackgroundLeft};
      }
  `}
  }
`;

const Inside = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1024px;
  max-height: 768px;
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media ${props => props.theme.breakpoints.mobile} {
    height: auto;
    padding: 0 24px;
    max-height: none;
  }
`;

interface ContentProps {
  header: boolean;
  footer: boolean;
}

const Content = styled.div<ContentProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: calc(
    100% -
      ${({header, footer}) => {
        let delta = 0;
        if (header || footer) {
          delta = 100;
        }

        if (header && footer) {
          delta = 200;
        }

        return delta;
      }}px
  );

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
    min-height: calc(
      var(--vh, 1vh) * 100 -
        ${({header, footer}) => {
          let delta = 0;
          if (header || footer) {
            delta = 100;
          }

          if (header && footer) {
            delta = 200;
          }
          return delta;
        }}px
    );
  }

  @media ${props => props.theme.breakpoints.smallAndFat} {
    height: auto;
    min-height: auto;
  }
`;

export const ContentLeft = styled.div`
  width: 47%;
  padding-left: 96px;
  padding-right: 62px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    min-height: 200px;
  }
`;

export const ContentRight = styled.div`
  flex: 1;
  padding-left: 48px;
  padding-right: 96px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Header = styled.header`
  height: 100px;
  padding: 38px 96px;
  box-sizing: border-box;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 38px 0px;
  }
`;
