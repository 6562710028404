import React from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

import Button from '../../../atoms/Button';
import {Heading, Subtitle} from '../../../atoms/Typography';
import close from '../../../assets/icons/close-dark.svg';

interface Props {
  title: string;
  subtitle?: string;
  buttonText: string;
  imageUrl: string;
  visible: boolean;
  onClose: () => void;
  onAction: () => void;
}

const ImageModal: React.FC<Props> = ({
  title,
  subtitle,
  buttonText,
  imageUrl,
  visible,
  onClose,
  onAction,
}) => {
  if (!visible) {
    return null;
  }

  const root = document.getElementById('root');

  return (
    root &&
    ReactDom.createPortal(
      <Container>
        <ModalContainer>
          <CloseButton onClick={onClose} />
          <TextContainer>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
          </TextContainer>
          <ImageContainer>
            <Image imageUrl={imageUrl} />
          </ImageContainer>
          <Row>
            <StyledButton onClick={onAction}>{buttonText}</StyledButton>
          </Row>
        </ModalContainer>
      </Container>,
      root
    )
  );
};

export default ImageModal;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContainer = styled.div`
  overflow: hidden;
  width: 832px;
  border-radius: 24px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(31, 35, 39, 0.2);
  position: relative;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 48px 16px;
  justify-content: center;
  align-items: center;
  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 0 32px;
  }
`;

const Image = styled.div<{imageUrl: string}>`
  width: 547px;
  height: 296px;
  border-radius: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.imageUrl});
  background-position: center;
`;

const StyledTitle = styled(Heading)`
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.91px;
  line-height: 40px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 12px;
  font-weight: lighter;
`;

const Row = styled.div`
  padding: 32px 32px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    min-width: 100%;
    margin: 0;
    margin-bottom: 24px;
  }
`;

const CloseButton = styled.img.attrs({src: close})`
  cursor: pointer;
  position: absolute;
  top: 48px;
  right: 48px;
`;
